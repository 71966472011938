const pageToUrlMapping = {
  condo: {
    default: '/condos-for-sale',
    state: '/states/:areaSlug/homes-for-sale/condos',
    city: '/:areaSlug/homes-for-sale/condos',
    zip: '/condos-for-sale/:areaSlug',
    county: '/condos-for-sale/:areaSlug'
  },
  sfh: {
    default: '/single-family-homes-for-sale',
    state: '/states/:areaSlug/homes-for-sale/single-family-homes',
    city: '/:areaSlug/homes-for-sale/single-family-homes',
    zip: '/single-family-homes-for-sale/:areaSlug',
    county: '/single-family-homes-for-sale/:areaSlug'
  },
  townhome: {
    default: '/townhomes-for-sale',
    state: '/states/:areaSlug/homes-for-sale/townhomes',
    city: '/:areaSlug/homes-for-sale/townhomes',
    zip: '/townhomes-for-sale/:areaSlug',
    county: '/townhomes-for-sale/:areaSlug'
  },
  mobile: {
    default: '/mobile-homes-for-sale',
    state: '/states/:areaSlug/homes-for-sale/mobile-homes',
    city: '/:areaSlug/homes-for-sale/mobile-homes',
    zip: '/mobile-homes-for-sale/:areaSlug',
    county: '/mobile-homes-for-sale/:areaSlug'
  },
  bedroom: {
    default: '/:bedroomCount-bedroom-homes-for-sale',
    state: '/states/:areaSlug/homes-for-sale/:bedroomCount-bedroom',
    city: '/:areaSlug/homes-for-sale/:bedroomCount-bedroom',
    zip: '/:bedroomCount-bedroom-homes-for-sale/:areaSlug',
    county: '/:bedroomCount-bedroom-homes-for-sale/:areaSlug'
  },
  newest: {
    default: '/newest-listings',
    state: '/states/:areaSlug/homes-for-sale/newest-listings',
    city: '/:areaSlug/homes-for-sale/newest-listings',
    zip: '/newest-listings/:areaSlug',
    county: '/newest-listings/:areaSlug'
  },
  sold: {
    default: '/sold',
    state: '/states/:areaSlug/homes-for-sale/sold',
    city: '/:areaSlug/homes-for-sale/sold',
    zip: '/sold/:areaSlug',
    county: '/sold/:areaSlug'
  },
  undefined: {
    default: '/homes-for-sale',
    state: '/states/:areaSlug/homes-for-sale',
    city: '/:areaSlug/homes-for-sale',
    zip: '/homes-for-sale/zip/:areaSlug',
    county: '/homes-for-sale/county/:areaSlug'
  },
  featured: {
    default: '/featured',
    state: '/states/:areaSlug/featured',
    city: '/:areaSlug/featured',
    zip: '/featured/zip/:areaSlug',
    county: '/featured/county/:areaSlug'
  }
}

export const buildSearchUrl = (slug: string | null, pageType: string, areaType: string, bedroomCount: number | null): string => {
  let path
  const pageTypeMapping = pageToUrlMapping[pageType]

  if (areaType) {
    path = pageTypeMapping[areaType]
  } else {
    path = pageTypeMapping['default']
  }

  path = path.replace(':areaSlug', slug)

  if (bedroomCount) {
    path = path.replace(':bedroomCount', bedroomCount)
  }

  return `${process.env.REACT_APP_HOMELIGHT_REPO_URL}${path}`
}
